import React from 'react'
import Meta from 'components/meta'
import Swiper from 'react-id-swiper';
import Layout from 'components/layout'

import 'swiper/css/swiper.css'

class HealthAnalysis extends React.Component {
  constructor(props) {
    super(props)
  }

  showAnalysisWidget = e => {
    ApeironWidget.init({
      id: '5edfb0bce115d5571ddf77a4',
      dialog: true,
      singleQuestionPerPage: true,
    });
    return false;
  }



  render() {
    const { location } = this.props
    const siteMetadata = {
      title: 'Health Analysis | Limitless With Jess',
      description: 'Gatsby starter for bootstrap a blog',
      siteUrl: 'https://limitlesswithjess.com/health-analysis',
    }
    const params = {
      effect: 'coverflow',
      grabCursor: true,
      centeredSlides: true,
      slidesPerView: 3,
      autoplay: {
        delay: 2500,
        disableOnInteraction: false
      },
      coverflowEffect: {
        rotate: 50,
        stretch: 0,
        depth: 100,
        modifier: 1,
        slideShadows: true
      },
      pagination: {
        el: '.swiper-pagination'
      }
      ,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      breakpoints: {
        1024: {
          slidesPerView: 3,
        },
        768: {
          slidesPerView: 2,
        },
        640: {
          slidesPerView: 1,
        },
        320: {
          slidesPerView: 1,
        },
      },
    }
    return (
      <Layout location={location}>
        <div>
          <Meta site={siteMetadata} />
          <div className="">
            <div className="cover-page">
              <div className="container">
                <section className="banner-section hlth-bnr">
                  <div className="data-name">
                    <h2 className=" coverPage-heading">
                      {' '}
                    Are you Ready to Experience the Benefits of a Genetically
                    Optimized Lifestyle?
                  </h2>
                  </div>
                  <div className="data-name">
                    <h2 className=" coverPage-subheading">
                      {' '}
                    Express Your Potential, Improve Performance, Optimize your
                    body, Clear your Mind
                  </h2>
                  </div>
                  <button
                    onClick={e => this.showAnalysisWidget(e)}
                    className="btn btn-lg btn-fancy px-4"
                  >
                    <img src="/assets/img/qcon.png" className="mr-3" width="20px" />
                  Get Started Now{' '}
                    <img src="/assets/img/qcon1.png" className="ml-3" width="17px" />
                  </button>
                </section>
              </div>{' '}
            </div>
            <div className="reportpdf-wraaper">
              <div className="container">
                <div
                  className="slide-container banner- pdf-banner "
                >
                  <h2 className="fancy-heading  mr-t-10 mr-b-10 text-center">Limitless Report Preview</h2>
                  <div className="mt-5 mb-5">
                    <Swiper {...params}>
                      <div className="text-center"><img src="/assets/img/report-pdf/report-1.png" /></div>
                      <div className="text-center"><img src="/assets/img/report-pdf/report-2.png" /></div>
                      <div className="text-center"><img src="/assets/img/report-pdf/report-3.png" /></div>
                      <div className="text-center"><img src="/assets/img/report-pdf/report-4.png" /></div>
                      <div className="text-center"><img src="/assets/img/report-pdf/report-5.png" /></div>
                      <div className="text-center"><img src="/assets/img/report-pdf/report-6.png" /></div>
                      <div className="text-center"><img src="/assets/img/report-pdf/report-7.png" /></div>
                      <div className="text-center"><img src="/assets/img/report-pdf/report-8.png" /></div>
                      <div className="text-center"><img src="/assets/img/report-pdf/report-9.png" /></div>
                      <div className="text-center"><img src="/assets/img/report-pdf/report-10.png" /></div>
                      <div className="text-center"><img src="/assets/img/report-pdf/report-11.png" /></div>
                      <div className="text-center"><img src="/assets/img/report-pdf/report-12.png" /></div>
                    </Swiper>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>

    )
  }
}

export default HealthAnalysis
